<template>
  <div :class="classColumns">
    <slot></slot>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    columns: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const classColumns = computed(() => `col-${props.columns}`);

    return {
      classColumns,
    };
  },
};
</script>
