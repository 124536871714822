/* eslint-disable no-unused-vars */
export const toDataUrl = (url, callback) => {
  var xhr = new XMLHttpRequest();
  xhr.onload = function() {
    var reader = new FileReader();
    reader.onloadend = function() {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};

export const getBase64FromUrl = async (url) => {
  const base64 = await new Promise((resolve, reject) => {
    toDataUrl(url, function(myBase64) {
      resolve(myBase64); 
    });
  });
  return base64;
};
