<template>
  <div class="bg-dark" :class="{ overlay: showModal }">
    <div class="container pt-2 mb-4">
      <div class="row justify-content-center m-3">
        <div class="col-12 col-md-8 mb-3">
          <div class="form-file">
            <input
              type="file"
              class="form-file-input"
              ref="imageFile"
              @change="handleCoverImageFile"
            />
            <label class="form-file-label" for="customFile">
              <span class="form-file-button btn-block"
                >⬆️ Clic aquí para subir tu foto</span
              >
            </label>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <button @click="downloadAdv" class="btn btn-danger btn-lg col-md-6 col-12">
            Descarga <download-icon v-if="!loading"></download-icon>
            <loading-icon v-if="loading"></loading-icon>
          </button>
          <button @click="shareTwitter" class="btn btn-primary btn-lg col-md-6 col-12">
            Comparte <twitter-icon></twitter-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="position-relative d-flex justify-content-center">
      <image-effect
        :selectedEffect="selectedEffect"
        :src="coverImage"
        :profile="selectedProfile"
        :imageSizeRatio="coverImageSizeRatio"
        :imagePositionV="coverImagePositionV"
        :imagePositionH="coverImagePositionH"
        :imageColorBackground="selectedColor"
        :imageColorPattern="selectedPatternColor"
        :imageClipShape="selectedClipShape"
        width="400"
        height="400"
      />
    </div>
  </div>

  <section class="bg-light bg-gradient pt-2 pb-5">
    <div class="container">
      <div class="row">
        <div
          class="btn-group d-flex justify-content-center"
          role="group"
          aria-label="Select tipo"
        >
          <input
            type="radio"
            class="btn-check"
            name="profiles"
            id="meerkat"
            v-model="selectedProfile"
            value="meerkat"
            autocomplete="off"
          />
          <label class="btn btn-outline-primary" for="meerkat">
            Me siento suricata</label
          >
          <input
            type="radio"
            class="btn-check"
            name="profiles"
            id="turtle"
            v-model="selectedProfile"
            value="turtle"
            autocomplete="off"
          />
          <label class="btn btn-outline-primary" for="turtle">
            Me siento tortuga</label
          >
          <input
            type="radio"
            class="btn-check"
            name="profiles"
            id="pantufler"
            v-model="selectedProfile"
            value="pantufler"
            autocomplete="off"
          />
          <label class="btn btn-outline-primary" for="pantufler">
            Yo, en pantuflas</label
          >
        </div>
        <div class="d-grid gap-2 d-flex justify-content-between justify-content-md-end">
          <button class="btn btn-outline d-md-none" @click="toggleControlColors">
            {{
              controlColorsVisible
                ? "X Ocultar control colores"
                : "👁 Mostrar control colores"
            }}
          </button>
          <button class="btn btn-outline" style="justify-self: end" @click="showModal2 = true">
            ¿Qué es esto?
          </button>
        </div>
        <div class="col-lg-6 col-12" v-if="controlColorsVisible">
          <div class="form-title my-1">Color de fondo</div>
          <div
            class="btn-group btn-group--badges"
            role="group"
            aria-label="Select colors"
          >
            <template v-for="color in colors" :key="color">
              <input
                type="radio"
                class="btn-check"
                name="colors"
                :id="color"
                v-model="selectedColor"
                :value="color"
                autocomplete="off"
              />
              <label class="btn btn-outline-primary" :for="color">
                <span class="badge" :style="{ background: color }"
                  >&nbsp;</span
                ></label
              >
            </template>
          </div>
          <div class="form-title my-1">Color del patrón</div>
          <div
            class="btn-group btn-group--badges"
            role="group"
            aria-label="Select colors"
          >
            <template v-for="color in colors" :key="color">
              <input
                type="radio"
                class="btn-check"
                name="pattern-colors"
                :id="`pattern-${color}`"
                v-model="selectedPatternColor"
                :value="color"
                autocomplete="off"
              />
              <label class="btn btn-outline-primary" :for="`pattern-${color}`">
                <span class="badge" :style="{ background: color }"
                  >&nbsp;</span
                ></label
              >
            </template>
          </div>
          <div>
            <div class="form-title my-1">Recorte de imagen</div>
            <div class="btn-group" role="group" aria-label="Select shape">
              <template v-for="clipShape in clipShapes" :key="clipShape">
                <input
                  type="radio"
                  class="btn-check"
                  name="clipShapes"
                  :id="clipShape"
                  v-model="selectedClipShape"
                  :value="clipShape"
                  autocomplete="off"
                />
                <label class="btn btn-outline-primary" :for="clipShape">
                  <img
                    :src="`assets/shape-${clipShape}.png`"
                    alt="`Recorte tipo ${clipShape}`"
                /></label>
              </template>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 mt-md-4 mt-sm-0">
          <RowControls>
            <div class="mb-0">
              <RangeInput
                v-model:range="coverImageSizeRatio"
                min="0.7"
                max="3"
                step="0.01"
              >
                Tamaño de la imagen</RangeInput
              >
            </div>
            <template #columnOne
              ><ColumnControls columns="md-6" class="row">
                <RangeInput v-model:range="coverImagePositionV"
                  >Vert.</RangeInput
                >
              </ColumnControls></template
            >
            <template #columnTwo
              ><ColumnControls columns="md-6" class="row">
                <RangeInput v-model:range="coverImagePositionH"
                  >Horiz.</RangeInput
                >
              </ColumnControls></template
            >
          </RowControls>

          <hr class="my-4" />
        </div>
      </div>
    </div>
  </section>
  <modal-message
    :showModal="showModal"
    @close="showModal = false"
    @go="showModal = false"
    actionButtonEnabled
    ><h3>¡Crea tu badge exclusivo!</h3>
    <p>
      1️⃣ Sube tu foto, 2️⃣ Elige tu equipo y 3️⃣ Cambia los colores a tu gusto
      (mira en la parte de abajo de la pantalla)
      <br />
      Haz tuyo el nuevo diseño de Web Reactiva y siente tus colores #Team{{profilesTranslated[selectedProfile]}} 🎉
    </p></modal-message
  >
  <modal-message
    :showModal="showModal2"
    @close="showModal2 = false"
    @go="showModal2 = false"
    closeButtonEnabled
    >
    <p>
      Esto es un juego para descubrir los colores, formas y tipos de letra de los proyectos de Web Reactiva, la Reactivísima y danielprimo.io.
      <br />
      Puedes cambiar el badge añadiendo tu foto, modificando el equipo y cambiando los colores del fondo y del patrón.
      <br />
      Si necesitas más info, no dejes de preguntarme en <a href="https://danielprimo.io/contacto" target="_blank">mi formulario de contacto</a>.
    </p></modal-message
  >
</template>

<script>
import domtoimage from "dom-to-image";
import { getBase64FromUrl } from "../utils/convertToBase64";
import ImageEffect from "./ImageEffect";
import LoadingIcon from "./LoadingIcon";
import DownloadIcon from "./DownloadIcon";
import TwitterIcon from "./TwitterIcon";
import ModalMessage from "./ModalMessage";
import RangeInput from "./RangeInput";
import RowControls from "./RowControls";
import ColumnControls from "./ColumnControls";

export default {
  components: {
    ImageEffect,
    LoadingIcon,
    DownloadIcon,
    ModalMessage,
    RangeInput,
    RowControls,
    ColumnControls,
    TwitterIcon
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      profiles: ["meerkat", "turtle", "pantufler"],
      profilesTranslated: {
        meerkat: "Suricata",
        turtle: "Tortuga",
        pantufler: "Pantufler"
      },
      colors: [
        "#E56A54",
        "#9678D3",
        "#FED757",
        "#7DC5F1",
        "#B7428E",
        "#9EF4A4",
        "#F25673",
        "#2373EE",
        "#FFB0C0",
        "#56C2B7",
        "#ffffff",
        "#000000",
      ],
      clipShapes: ["codeblock", "hexagon", "parenthesis"],
      loading: false,
      loadingImage: false,
      showModal: false,
      showModal2: false,
      controlColorsVisible: true,
      coverImageFile: null,
      coverImageApi: null,
      coverImageSizeRatio: 1,
      coverImagePositionV: "50",
      coverImagePositionH: "50",
      selectedProfile: "meerkat",
      selectedColor: "#9678D3",
      selectedPatternColor: "#FED757",
      selectedClipShape: "codeblock",
    };
  },
  computed: {
    defaultCoverImage() {
      return `assets/default-${this.selectedProfile}.png`;
      //return "https://www.gravatar.com/avatar/00000000000000f00000000000000000?d=robohash&f=y&s=400&r=pg";
      //return `${this.publicPath}assets/background.jpg`;
    },
    coverImage() {
      if (this.coverImageFile !== null) {
        return this.coverImageFile;
      }
      if (this.coverImageApi !== null) {
        return this.coverImageApi;
      }
      return this.defaultCoverImage;
    },
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    if (params.has("tipo")) {
      if (params.get("tipo") == "tortuga") {
        this.selectedProfile = "turtle";
      } else {
        this.selectedProfile = "meerkat";
      }
    } else {
      this.selectedProfile = "meerkat";
    }
    this.showModal = true;
    //this.defaultCoverImage = `${this.publicPath}assets/background.jpg`
  },
  methods: {
    toggleControlColors() {
      this.controlColorsVisible = !this.controlColorsVisible;
    },
    handleCoverImageFile(event) {
      this.coverImageApi = null;
      this.coverImageFile = URL.createObjectURL(event.target.files[0]);
      this.coverImageFile = URL.createObjectURL(event.target.files[0]);
      getBase64FromUrl(this.coverImageFile).then((base64) => {
        base64;
      });
    },
    shareTwitter() {
      let profile = this.profilesTranslated[this.selectedProfile];
      let slogan = this.selectedProfile == "meerkat" ? "¡Aúpa Suricatas!" : "¡Fuerza Tortugas!";
      slogan = this.selectedProfile == 'pantufler' ? "¡Con pantuflas se vive mejor!" : slogan; 
      const text = `Soy parte del #Team${profile} 
${slogan} 🥳`;
      const url = `https://twitter.com/intent/tweet?in_reply_to=1533353080393170946&text=${encodeURIComponent(text)}`;

      var link = document.createElement("a");
      link.href = url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    },
    downloadAdv() {
      const self = this;
      this.loading = true;
      const node = document.getElementById("cover-image-container");

      const exportAsImage = () => {
        try {
          var options = {
            style: {
              width: "400px",
              height: "400px",
            },
          };

          domtoimage.toPng(node, options).then(function(dataUrl) {
            dataUrl;
            domtoimage.toPng(node, options).then(function(dataUrlAfter) {
              var img = document.createElement("img");
              img.src = dataUrlAfter;
              img.setAttribute("style", "display: none;");
              document.body.appendChild(img);

              var link = document.createElement("a");
              link.download = "web-reactiva-team-builder.png";
              link.href = dataUrlAfter;
              document.body.appendChild(link);
              link.click();
              self.loading = false;
              document.body.removeChild(link);
            });
          });
        } catch (err) {
          console.log(err.message);
        }
      };

      exportAsImage();
    },
    download() {
      this.loading = true;
      const node = document.getElementById("cover-image-container");

      domtoimage
        .toPng(node, {
          style: {
            width: "400px",
            height: "400px",
          },
        })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "web-reactiva-team-builder.png";
          link.href = dataUrl;
          document.body.appendChild(link);
          link.click();
          this.loading = false;
          document.body.removeChild(link);

          var img = document.createElement("img");
          img.src = dataUrl;
          document.body.appendChild(img);
        });
    },
  },
};
</script>

<style>
.overlay {
  opacity: 0.2;
}
.btn-group--badges {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.btn-group--badges label {
  max-width: 40px;
  border: none;
  padding: 0.3em;
}
.btn-group--badges label span.badge {
  left: 0;
}

.col-form-label {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
