<template>
  <label class="col-auto col-form-label"
    ><slot>Range</slot></label
  >
  <div class="col-sm-8 pt-1">
    <input
      type="range"
      class="form-range"
      v-model="updateRange"
      :step="step"
      :min="min"
      :max="max"
    />
  </div>
</template>

<script>
import { useModelWrapper } from "../utils/modelWrapper";

export default {
  props: {
    range: String,
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 0.01
    }
  },
  setup(props, { emit }) {
    return {
      updateRange: useModelWrapper(props, emit, "range"),
    };
  },
};
</script>

<style></style>
