<template>
  <div
    class="modal"
    :class="{ show: showModal ? 'show' : '' }"
    :style="{ display: showModal ? 'block' : 'none' }"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('close')"
          ></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <button
            v-show="closeButtonEnabled"
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click="$emit('close')"
          >
            {{ closeText }}
          </button>
          <button
            v-show="actionButtonEnabled"
            type="button"
            class="btn btn-primary"
            @click="emit()"
          >
            {{ actionText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    bodyText: {
      type: String,
    },
    closeButtonEnabled: {
      type: Boolean,
      default: false,
    },
    closeText: {
      type: String,
      default: "Cerrar",
    },
    actionButtonEnabled: {
      type: Boolean,
      default: false,
    },
    actionText: {
      type: String,
      default: "¡Vamos!",
    },
  },
  methods: {
    emit() {
      this.$emit("go");
      this.$emit("close");
    },
  },
};
</script>
