<template>
  <div class="navbar navbar-dark bg-dark shadow-sm">
    <div class="container justify-content-center">
      <span class="navbar-brand d-flex align-items-center">
        <strong style="font-family: 'Space Grotesk', sans-serif;">Únete a tu equipo ;)</strong>
      </span>
    </div>
  </div>
  <Dashboard />
  <footer class="text-muted py-5">
    <div class="container">
      <p class="mb-1">Hecho con ❤ por <a href="https://www.danielprimo.io/">danielprimo.io</a> y la #ComunidadMalandriner</p>
    </div>
  </footer>

</template>

<script>
import Dashboard from "./components/Dashboard.vue";

export default {
  name: "App",
  components: {
    Dashboard,
  },
};
</script>

<style>
body, p, div, button {
  font-family: "Space Grotesk", sans-serif;
}
</style>

